import { mapActions, mapGetters, mapMutations } from 'vuex'
import CountryFlag from 'vue-country-flag'
import DialogView from '../dialog-view/index.vue'
import PositionFilter from '../../components/filters/index.vue'
import { positionTableHead } from '../../helpers'

export default {
  name: 'user-position-list',
  data () {
    return {
      showRemoveDialog: false,
      removingItem: null,

      page: 1,
      filter: {
        keywords: '',
        countryId: ''
      },

      isLoadingCheckItem: false
    }
  },
  components: {
    PositionFilter,
    DialogView,
    CountryFlag
  },
  computed: {
    ...mapGetters({
      list: 'userPositions/list',
      limit: 'userPositions/limit',
      listLength: 'userPositions/listLength',
      isLoading: 'userPositions/isLoading',
      isListLoading: 'userPositions/isListLoading',
      openDialogView: 'userPositions/openDialogView',

      checkList: 'userPositions/checkList',
      isDeleteLoading: 'userPositions/isDeleteLoading',
    }),
    positionTableHead
  },
  created () {
    this.fetchList('all')
  },
  methods: {
    ...mapActions({
      fetchList: 'userPositions/GET_LIST',
      checkItemInMaterials: 'userPositions/CHECK_ITEM_IN_MATERIALS',
      checkItemInAducationMaterials: 'userPositions/CHECK_ITEM_IN_ADUCATION_MATERIALS',
      deleteItem: 'userPositions/DELETE_ITEM',
    }),
    ...mapMutations({
      changeSkip: 'userPositions/CHANGE_SKIP',
      changeDialogView: 'userPositions/CHANGE_DIALOG_VIEW',
      setList: 'userPositions/SET_LIST',
      setListItem: 'userPositions/SET_ITEM',
      resetCheckList: 'userPositions/RESET_CHECK_LIST'
    }),
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList('all')
    },
    changeDialog (status = false, item = null) {
      this.setListItem(item)
      this.changeDialogView(status)
      if (!item) {
        this.setListItem(null)
      }
    },

    changeDialogDeleteItem (item, e) {
      let $btn = e.currentTarget;

      this.isLoadingCheckItem = true;
      $btn.classList.add('has-disabled');

      this.resetCheckList();

      //need to add this in promise all
      this.checkItemInMaterials(item.id);

      this.checkItemInAducationMaterials(item.id).then(() => {
          $btn.classList.remove('has-disabled');
          this.isLoadingCheckItem = false;
          this.showRemoveDialog = true;

          // eslint-disable-next-line camelcase
          const { course_id, module_id } = this.$route.params
          this.removingItem = {
              course_id: course_id,
              module_id: module_id,
              type: item.material,
              id: item.id
          }
      })
    },

    remove(){
      this.deleteItem(this.removingItem.id).then(() => {
		  this.showRemoveDialog = false;
		  this.removingItem = {}
      })
    }
  },
  destroyed () {
    this.setList([])
    this.setListItem(null)
  }
}
