import { mapActions, mapGetters, mapMutations } from 'vuex'
import general from '../general/index.vue'

export default {
  name: 'user-position-dialog-view',
  data () {
    return {
      dialog: true
    }
  },
  components: {
    general
  },
  watch: {
    openDialogView () {
      this.dialog = this.openDialogView
    }
  },
  computed: {
    ...mapGetters({
      currentItem: 'userPositions/currentItem',
      openDialogView: 'userPositions/openDialogView',
      isLoading: 'userPositions/isLoading'
    })
  },
  methods: {
    ...mapActions({
      fetchItem: 'userPositions/GET_LIST_ITEM',
      update: 'userPositions/UPDATE',
      create: 'userPositions/CREATE'
    }),
    ...mapMutations({
      changeDialogView: 'userPositions/CHANGE_DIALOG_VIEW',
      setItem: 'userPositions/SET_ITEM'
    }),
    changeCloseDialog () {
      this.dialog = false
      this.changeDialogView(false)
    }
  },
  destroyed () {
    this.setItem(null)
  }
}
