import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'
import { filterProps } from '../../helpers'
import validation from '@/mixins/validation'
import mixins from '@/mixins'

export default {
  name: 'position-filter',
  mixin: [mixins, validation],
  data () {
    return {
      filter: {
        keywords: '',
        countries: [],
        positionId: '',
        active: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      filters: 'userPositions/filter',
      accessCountries: 'profile/accessCountries'
    }),
    ...filterProps
  },
  created () {
    Object.assign(this.filter, this.filters)
  },
  methods: {
    ...mapActions({
      fetchList: 'userPositions/GET_LIST',
      fetchUsersBasic: 'user/GET_BASIC_USERS',
      fetchCourse: 'homeWorkResult/GET_COURSE'
    }),
    ...mapMutations({
      changeFilter: 'userPositions/CHANGE_FILTER'
    }),
    sendRequest: debounce(function () {
      this.fetchList()
    }, 500),
    changeFilterLocal (val, type, isReset = false) {
      this.changeFilter({
        value: Array.isArray(val) ? val.join(',') : val || '',
        type: type,
        isClear: isReset
      })
      this.sendRequest()
    }
  },
  destroyed () {
    this.changeFilter({ isClear: true })
  }
}
